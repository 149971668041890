import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import { useMediaQuery } from 'react-responsive'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import { SimpleHeader } from '../components/Header'
import { PressFeed } from '../components/Press'
import { mobile } from '../styles/breakpoint'

export default function News({ data }) {
  const {
    allStrapiPress: { nodes: pressItems },
  } = data

  const isMobile = useMediaQuery({ query: mobile })

  useEffect(() => {
    if (!isMobile) {
      navigate('/')
    }
  }, [isMobile])

  return (
    <>
      <SEO title="News" />
      <SimpleHeader />
      <main
        style={{
          padding: 'var(--spacing)',
          position: 'relative',
        }}
      >
        <PressFeed items={pressItems} />
      </main>
    </>
  )
}

export const query = graphql`
  query {
    allStrapiPress(sort: { fields: date, order: DESC }) {
      nodes {
        url
        date(formatString: "D MMMM YYYY")
        id
        image_url
        fields {
          bodyHTML
          headlineHTML
        }
        image {
          extension
          base
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
